import React from "react";

import { mapModifiers } from "helpers/component";

export type TextModifiers =
	| "black"
	| "spanishBlue"
	| "charlestonGreen"
	| "yankeesBlue"
	| "18x26";
export interface Props {
	centered?: boolean;
	children?: React.ReactNode;
	modifiers?: TextModifiers[];
}

export const Text: React.FC<Props> = ({ centered, children, modifiers }) => (
	<p className={mapModifiers("a-text", centered && "centered", modifiers)}>
		{children}
	</p>
);
