import http from "services/http";

import { LoginPayload, LoginResponse, UserProfile } from "./types";

export const apiLogin = ({ shopId, password, phone }: LoginPayload) =>
	http.post<BaseAPIResponse<LoginResponse>>(`/pg/login`, {
		shop_id: shopId,
		password,
		phone,
	});

export const apiGetUserProfile = () =>
	http.get<BaseAPIResponse<UserProfile>>(`/pg/profile`, {
		requireAuthentication: true,
	});

export const apiLogout = () =>
	http.post("/pg/logout", undefined, { requireAuthentication: true });
