import React, { useEffect, useRef, useState } from "react";

import banner from "assets/images/common/banner.png";
import { Button } from "components/atoms/Button";
import { Image } from "components/atoms/Image";
import { Container } from "components/organisms/Grid";
import { General } from "components/pages/general";
import GiftExchange from "components/templates/GiftExchange";
import PreCustomerInfo from "components/templates/PreCustomerInfo";
import { SavedCustomer } from "pages";
import CustomerInfo from "pages/CustomerInfo";
import Photo from "pages/Photo";
import { getLocalStorage, setLocalStorage } from "services/Sessions";
import { useAppSelector } from "store";

const Home: React.FC = () => {
	const [step, setStep] = useState(0);
	const { channel } = useAppSelector((store) => store.stores);
	const { userProfile } = useAppSelector((store) => store.auth);
	const savedCustomerRef = useRef<SavedCustomer | undefined>(undefined);
	const remainingGifRef = useRef<number | undefined>(undefined);
	const infoStep = getLocalStorage("infoStep");

	// useEffect(() => {
	// 	if (infoStep) setStep(Number(infoStep));
	// }, [infoStep]);

	/**
	 * Back to first page
	 */
	const backToStart = () => {
		savedCustomerRef.current = undefined;
		setStep(0);
		window.localStorage.removeItem("infoStep");
	};

	const handleGotoNextStep = () => {
		setStep((prevStep) => prevStep + 1);
	};

	const getRemainingGift = (quantity: number) => {
		remainingGifRef.current = quantity;
	};

	const getSavedCustomer = (savedCustomer: SavedCustomer) => {
		savedCustomerRef.current = savedCustomer;
	};

	const handleSubmitGiftInfo = () => {
		handleGotoNextStep();
	};

	const handleGotoPrevStep = () => {
		setStep((prevStep) => prevStep - 1);
		if (channel === "GT") {
			savedCustomerRef.current = undefined;
		}
	};

	const handleReset = () => {
		savedCustomerRef.current = undefined;
		remainingGifRef.current = undefined;
		setStep(0);
	};

	const renderContent = () => {
		switch (step) {
			case 0:
				return (
					<>
						<div style={{ maxWidth: 540 }} className="mx-auto">
							<Image src={banner} alt="banner" />
						</div>
						<Container className="u-mt-20 u-pt-5">
							<div className="p-home_activation_button u-mt-20 u-pt-15 d-flex justify-content-center">
								<Button
									onClick={() => {
										setStep(1);
										setLocalStorage("infoStep", "1");
									}}
								>
									HOẠT ĐỘNG PRE-ACTIVATION
								</Button>
							</div>
							<div className="p-home_activation_button u-mt-20 u-pt-15 d-flex justify-content-center">
								<Button
									onClick={() => {
										setStep(2);
										setLocalStorage("infoStep", "2");
									}}
								>
									HOẠT ĐỘNG ACTIVATION
								</Button>
							</div>
						</Container>
					</>
				);
			case 1:
				return <PreCustomerInfo handleBack={backToStart} />;
			case 2:
				return (
					<CustomerInfo
						onSubmit={() => setStep(3)}
						onReload={backToStart}
						getRemainingGift={getRemainingGift}
						savedCustomer={savedCustomerRef.current}
						getSavedCustomer={getSavedCustomer}
						handleBack={backToStart}
						channel={(userProfile?.channel as "GT" | "MT") || channel}
					/>
				);
			case 3:
				if (channel) {
					return (
						<GiftExchange
							channel={channel}
							onSubmit={handleSubmitGiftInfo}
							goBack={handleGotoPrevStep}
						/>
					);
				}
				return <>trong khong</>;
			case 4:
				return <Photo onSubmit={handleReset} />;
			default:
				return null;
		}
	};
	return (
		<General helmet={<title>Activation</title>}>{renderContent()}</General>
	);
};

export default Home;
