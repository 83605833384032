import http from "services/http";

import {
	CustomerByPhone,
	CustomerPreSaveParamsTypes,
	FindCustomerByPhoneParamsTypes,
	OtpVerification,
	PreActiveCustomerTypes,
	SavingCustomer,
	SendOtpParamsTypes,
} from "./types";

export const apiFindCustomerByPhone = (
	params: FindCustomerByPhoneParamsTypes
) =>
	http.get<BaseAPIResponse<CustomerByPhone>>(
		`/customers/${params.phone}?is_glucerna=${params.is_glucerna}`,
		{
			requireAuthentication: true,
		}
	);

export const apiSendOtp = (payload: SendOtpParamsTypes) =>
	http.post(
		`/customers/otp/send?is_glucerna=${payload.is_glucerna}`,
		payload.data,
		{ requireAuthentication: true }
	);

export const apiVerifyOtp = (payload: OtpVerification) =>
	http.post(
		`/customers/otp/verify?is_glucerna=${payload.is_glucerna}`,
		payload.params,
		{ requireAuthentication: true }
	);

export const postCustomerPreSave = async (params: CustomerPreSaveParamsTypes) =>
	http.post("/customers/pre-save", params, { requireAuthentication: true });

export const getPreActiveCustomer = async (
	params: string
): Promise<PreActiveCustomerTypes> => {
	const res = await http.get(`/customers/pre-active/${params}`, {
		requireAuthentication: true,
	});
	return res.data.data;
};

export const apiSaveCustomer = (
	{
		address,
		birthYear,
		name,
		phone,
		sample,
		sachet,
		ensureTarget,
		glucernaTarget,
	}: SavingCustomer,
	isGlucerna: number
) =>
	http.post<BaseAPIResponse<CustomerByPhone>>(
		`/customers/save?is_glucerna=${isGlucerna}`,
		{
			address,
			name,
			phone,
			sachet,
			sample,
			birth_year: birthYear,
			ensure_target: ensureTarget,
			glucerna_target: glucernaTarget,
		},
		{ requireAuthentication: true }
	);
