import React from "react";

import logo from "assets/images/common/logo.png";
import { Image } from "components/atoms/Image";
import { Text } from "components/atoms/Text";
import { Main } from "components/organisms/Main";
import { mapModifiers } from "helpers/component";

type Modifier = "login" | "glucerna" | "default";

export interface Props {
	modifiers?: Modifier | Array<Modifier>;
}

export const MainLayout: React.FC<Props> = ({ children, modifiers }) => {
	return (
		<div className={mapModifiers("t-mainlayout", modifiers)}>
			<Main>{children}</Main>
			<div className="t-mainlayout_bottom">
				{/* <Text>ENS-I-499-21</Text> */}
				<Image src={logo} alt="logo" />
			</div>
		</div>
	);
};
