import React from "react";

import { mapModifiers } from "helpers/component";

type Modifier = "transparent" | "boxShadow";

export interface Props {
	modifiers?: Modifier | Modifier[];
	src: string;
	alt: string;
	lazy?: boolean;
	aspectRatio?: "photo" | "loginbanner" | "capture";
	rounded?: boolean;
	onClick?: () => void;
}

export const Image: React.FC<Props> = ({
	src,
	alt,
	modifiers,
	aspectRatio,
	rounded,
	lazy,
	onClick,
}) => (
	<div
		className={mapModifiers(
			"a-image",
			modifiers,
			aspectRatio,
			aspectRatio ? "fixed" : undefined,
			rounded && `rounded`
		)}
		onClick={onClick}
		aria-hidden
	>
		<img
			className="a-image_img"
			loading={lazy ? "lazy" : undefined}
			src={src}
			alt={alt}
		/>
	</div>
);

Image.defaultProps = {
	lazy: true,
};
