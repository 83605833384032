/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";

import { boolean } from "yup";

import banner from "assets/images/common/ensureGold.png";
import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";
import { Image } from "components/atoms/Image";
import { PhonefieldHookForm } from "components/atoms/PhoneField";
import { TextfieldHookForm } from "components/atoms/TextField";
import { toastSingleMode } from "components/atoms/Toastify";
import { FormField } from "components/molecules/FormField";
import { Container } from "components/organisms/Grid";
import { General } from "components/pages/general";
import { FormContainer, useFormContainer } from "helpers/form";
import { useAsyncAction } from "hooks/useAsyncAction";
import { apiPrizesExchange } from "services/Exchange";

export interface Props {
	channel: "MT" | "GT";
	onSubmit?: (numberOfRotation: number) => void;
	goBack: () => void;
}

const GIFT_FORM_FIELD = [
	{
		name: "goldGH400g",
		label: "Số lon Ensure Gold GH 400g",
	},
	{
		name: "goldGH850g",
		label: "Số lon Ensure Gold GH 850g",
	},
	{
		name: "glassOneHandle",
		label: "Quà 1 ly quai",
		isGift: true,
		isRead: false,
	},
	{
		name: "shaker",
		label: "Quà 1 bình lắc",
		isGift: true,
		isRead: false,
	},
	{
		name: "averageThermostats",
		label: "Quà tặng bình giữ nhiệt",
		isGift: true,
		isRead: false,
	},
	{
		name: "scaleGold",
		label: "Quà 1 cân điện tử",
		isGift: true,
		isRead: false,
	},
	{
		name: "pillow",
		label: "Quà 1 Gối kê",
		isGift: true,
		isRead: false,
	},
	{
		name: "oneThermometer",
		label: "Quà 1 nhiệt kế",
		isGift: true,
		isRead: false,
	},
	{
		name: "helmet",
		label: "Quà 1 nón bảo hiểm",
		isGift: true,
		isRead: false,
	},
	{
		name: "blender",
		label: "Quà 1 máy vắt cam",
		isGift: true,
		isRead: false,
	},
	{
		name: "massageBelt",
		label: "Quà 1 đai massage",
		isGift: true,
		isRead: false,
	},
	{
		name: "sphygmomanometer",
		label: "Quà 1 máy đo huyết áp",
		isGift: true,
		isRead: false,
	},
	{
		name: "medicalBag",
		label: "Quà 1 túi y tế",
		isGift: true,
		isRead: false,
	},
];

type GIFT_CODE =
	| "BINH_LAC"
	| "BINH_GIU_NHIET"
	| "CAN_DIEN_TU"
	| "GOI_KE"
	| "NHIET_KE"
	| "NON_BAO_HIEM"
	| "DAI_MASSAGE"
	| "MAY_VAT_CAM"
	| "MAY_DO_HUYET_AP"
	| "TUI_Y_TE"
	| "LY_CO_QUAI";

const returnGiftText = (code: GIFT_CODE) => {
	switch (code) {
		case "LY_CO_QUAI":
			return "Ly có quai";
		case "BINH_LAC":
			return "Bình lắc";
		case "BINH_GIU_NHIET":
			return "Bình giữ nhiệt";
		case "CAN_DIEN_TU":
			return "Cân điện tử";
		case "GOI_KE":
			return "Gối kê";
		case "NHIET_KE":
			return "Nhiệt kế";
		case "MAY_VAT_CAM":
			return "Máy vắt cam";
		case "NON_BAO_HIEM":
			return "Nón bảo hiểm";
		case "DAI_MASSAGE":
			return "Đai massage";
		case "MAY_DO_HUYET_AP":
			return "Máy đo huyết áp";
		case "TUI_Y_TE":
			return "Túi y tế";
		default:
			return "";
	}
};

const GiftExchange: React.FC<Props> = ({ channel, onSubmit, goBack }) => {
	const { register, methodsRef } = useFormContainer();
	const [stallNumValue, setStallNumValue] = useState(0);
	const [totalGift, setTotalGift] = useState(0);

	const errorFunc = (message: string) => {
		toastSingleMode({
			type: "error",
			description: message,
		});
	};
	const [prizesExchange, prizesExchangeState] = useAsyncAction(
		apiPrizesExchange,
		{
			onSuccess: () => {
				const { getValues } = methodsRef.current;
				if (!onSubmit) return;

				onSubmit(Number(getValues("turns") || 0));
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			onFailed: (err: any) => {
				const { errors } = err.response.data;
				if (Array.isArray(errors)) {
					errors.forEach((val) => {
						if (val.code === "4223") {
							errorFunc(
								`Số lượng tồn kho quà ${returnGiftText(val.field)} không đủ`
							);
						}
					});
				}
			},
		}
	);

	/**
	 * Calculation Gift quatity
	 */
	const calculateGiftExchangeForm = () => {
		const { getValues, setValue } = methodsRef.current;

		const formValues = getValues();

		const giftExchangePayload = {
			goldGH400g: Number(formValues.goldGH400g) || 0,
			goldGH850g: Number(formValues.goldGH850g) || 0,
			glassOneHandle: Number(formValues.glassOneHandle) || 0,
			shaker: Number(formValues.shaker) || 0,
			averageThermostats: Number(formValues.averageThermostats) || 0,
			scaleGold: Number(formValues.scaleGold) || 0,
			pillow: Number(formValues.pillow) || 0,
			oneThermometer: Number(formValues.oneThermometer) || 0,
			helmet: Number(formValues.helmet) || 0,
			massageBelt: Number(formValues.massageBelt) || 0,
			blender: Number(formValues.blender) || 0,
			sphygmomanometer: Number(formValues.sphygmomanometer) || 0,
			medicalBag: Number(formValues.medicalBag) || 0,
		};

		// /**
		//  * Mua 1 lon GH 400g  – tặng 1 ly có quai
		//  */
		// const remaining400Type = giftExchangePayload.goldGH400g;

		// setValue("glassOneHandle", remaining400Type);

		setTotalGift(
			// remaining400Type +
			giftExchangePayload.glassOneHandle +
				giftExchangePayload.shaker +
				giftExchangePayload.averageThermostats +
				giftExchangePayload.scaleGold +
				giftExchangePayload.pillow +
				giftExchangePayload.oneThermometer +
				giftExchangePayload.helmet +
				giftExchangePayload.massageBelt +
				giftExchangePayload.sphygmomanometer +
				giftExchangePayload.medicalBag
		);
	};

	/**
	 * SUBMIT
	 */

	const handleSubmit = () => {
		const { getValues } = methodsRef.current;

		const formValues = getValues();
		const totalGiftSubmit =
			Number(formValues.glassOneHandle) +
			Number(formValues.oneThermometer) +
			Number(formValues.massageBelt) +
			Number(formValues.scaleGold) +
			Number(formValues.pillow) +
			Number(formValues.shaker) +
			Number(formValues.averageThermostats) +
			Number(formValues.blender) +
			Number(formValues.medicalBag) +
			Number(formValues.sphygmomanometer) +
			Number(formValues.helmet);
		setTotalGift(totalGiftSubmit);

		if (totalGift < 1) {
			errorFunc("Vui lòng chọn quà");
			return;
		}
		/**
		 * •Group 1: Mua 1 lon Ensure Gold Green Health 400g - tặng 1 ly có quai /  1 bình lắc
		 * •Group 2: 1 lon Ensure Gold Green Health 850g (1 trong các quà sau)
		 * | - tặng 1 Bình lắc
		 * | - tặng goi ke
		 * | - tặng cân
		 * •Group 3: 2 lon Ensure Gold Green Health 850g (1 trong các quà sau)
		 * | - tặng nhiệt kế
		 * | - tặng Nón BH
		 * | - tặng máy vắt cam
		 * •Group 4: Mua 2 lon GH 850g
		 * | – tặng đai massage
		 * | - Tặng 1 máy đo huyết áp
		 * | - Tặng 1 túi y tế
		 */

		/**
		 * Mua 1 lon Ensure Gold Green Health 400g - tặng 1 ly có quai
		 */

		const totalGH400g = Number(formValues.goldGH400g);
		// const remaingGH400g = totalGH400g - Number(formValues.glassOneHandle);
		// if (totalGH400g < Number(formValues.glassOneHandle)) {
		// 	errorFunc(`Tối đa ${totalGH400g.toString()} ly có quai`);
		// 	return;
		// }
		// if (
		// 	totalGH400g > Number(formValues.glassOneHandle) &&
		// 	remaingGH400g !== 0
		// ) {
		// 	errorFunc(
		// 		`Còn dư ${
		// 			totalGH400g - Number(formValues.glassOneHandle)
		// 		} lon Ensure Gold`
		// 	);
		// 	return;
		// }

		/**
		 * GROUP 2
		 */
		const glassOneHandle = Number(formValues.glassOneHandle);

		const shaker = Number(formValues.shaker);
		const averageThermostats = Number(formValues.averageThermostats);
		const pillow = Number(formValues.pillow) * 2;
		const scaleGold = Number(formValues.scaleGold) * 2;

		const oneThermometer = Number(formValues.oneThermometer) * 4;
		const helmet = Number(formValues.helmet) * 2;
		const blender = Number(formValues.blender) * 4;

		const massageBelt = Number(formValues.massageBelt) * 4;
		const sphygmomanometer = Number(formValues.sphygmomanometer) * 4;
		const medicalBag = Number(formValues.medicalBag) * 4;

		// Sum 1
		const sumGH850Gift1 =
			shaker + averageThermostats + pillow + scaleGold + glassOneHandle;

		// Sum 2
		const sumGH850Gift2 = oneThermometer + helmet + blender;

		//  Sum 3
		const sumGH850Gift3 = massageBelt + sphygmomanometer + medicalBag;

		// const sumGH850Gift = sumGH850Gift1 + sumGH850Gift2 + sumGH850Gift3;

		const totalGH400v800 = Number(formValues.goldGH850g) * 2 + totalGH400g;

		// const remaining850g = Number(formValues.goldGH850g) - sumGH850Gift;

		const sumGiftGH400v800 = sumGH850Gift1 + sumGH850Gift2 + sumGH850Gift3;
		const remaingGH400gv800 = totalGH400v800 - sumGiftGH400v800;

		if (totalGH400v800 < sumGiftGH400v800) {
			errorFunc(
				"Điều chỉnh lại số lượng ly thuỷ tinh, cân điện tử, bình lắc, gối kê , nhiệt kế, nón bảo hiểm, máy vắt cam, đai massage, nhiệt kế, túi y tế hoặc số lon GH 400g"
			);
			return;
		}
		if (totalGH400v800 > sumGiftGH400v800 && remaingGH400gv800 !== 0) {
			errorFunc(`Còn dư lon GH 400g hoặc lon GH 800g`);
			return;
		}

		// EXCUTE API
		prizesExchange({
			GH_400: Number(formValues.goldGH400g) || undefined,
			GH_850: Number(formValues.goldGH850g) || undefined,
			LY_CO_QUAI: Number(formValues.glassOneHandle) || undefined,
			CAN_DIEN_TU: Number(formValues.scaleGold) || undefined,
			NHIET_KE: Number(formValues.oneThermometer) || undefined,
			DAI_MASSAGE: Number(formValues.massageBelt) || undefined,
			BINH_LAC: Number(formValues.shaker) || undefined,
			BINH_GIU_NHIET: Number(formValues.averageThermostats) || undefined,
			NON_BH: Number(formValues.helmet) || undefined,
			GOI_KE: Number(formValues.pillow) || undefined,
			MAY_VAT_CAM: Number(formValues.blender) || undefined,
			MAY_DO_HUYET_AP: Number(formValues.sphygmomanometer) || undefined,
			TUI_Y_TE: Number(formValues.medicalBag) || undefined,
		});
	};

	return (
		<General helmet={<title>Thông tin đổi quà</title>}>
			<Container>
				<div className="d-flex justify-content-center mb-5 pb-2 pt-4">
					<Image src={banner} alt="banner" />
				</div>
				<div className="u-mb-15" style={{ maxWidth: 100 }}>
					<Button
						modifiers={["mediumSize", "secondary"]}
						onClick={goBack}
						fullWidth
					>
						Quay lại
					</Button>
				</div>
				<section>
					<div className="u-pb-5">
						<Heading>THÔNG TIN ĐỔI QUÀ</Heading>
					</div>
					<FormContainer validationSchema={{}} register={register}>
						{GIFT_FORM_FIELD.map((val, idx) =>
							val.isGift ? (
								<FormField
									key={`gift-${idx.toString()}`}
									label={val.label}
									modifiers="giftInfoMT"
								>
									<div style={{ paddingRight: 97 }}>
										<TextfieldHookForm
											name={val.name}
											onChange={calculateGiftExchangeForm}
											type="number"
											min={0}
											defaultValue={0}
											readOnly={val.isRead}
										/>
									</div>
								</FormField>
							) : (
								<FormField
									key={`item-${idx.toString()}`}
									label={<span>{val.label}</span>}
									modifiers="giftInfoMT"
								>
									<div style={{ paddingRight: 97 }}>
										<PhonefieldHookForm
											name={val.name}
											onChange={calculateGiftExchangeForm}
										/>
									</div>
								</FormField>
							)
						)}
						<div className="d-flex align-items-center">
							<div className="u-ml-6 u-ml-sm-10 u-mt-15">
								<Button
									modifiers="mediumSize"
									onClick={handleSubmit}
									loading={prizesExchangeState.loading}
								>
									NHẬN QUÀ
								</Button>
							</div>
						</div>
					</FormContainer>
				</section>
			</Container>
		</General>
	);
};

export default GiftExchange;
