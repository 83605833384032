/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";

import { AxiosError } from "axios";
import * as Yup from "yup";

import banner from "assets/images/common/ensureGold.png";
import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";
import { Image } from "components/atoms/Image";
import { PhonefieldHookForm } from "components/atoms/PhoneField";
import { TextfieldHookForm } from "components/atoms/TextField";
import { toastSingleMode } from "components/atoms/Toastify";
import { FormField } from "components/molecules/FormField";
import { Container } from "components/organisms/Grid";
import { General } from "components/pages/general";
import { FormContainer, useFormContainer } from "helpers/form";
import { postCustomerPreSave } from "services/Customer";

interface PreCustomerInfoProps {
	handleBack?: () => void;
}

const PreCustomerInfo: React.FC<PreCustomerInfoProps> = ({ handleBack }) => {
	const [isLoading, setIsLoading] = useState(false);
	const { register, methodsRef: formMethodsRef } = useFormContainer();

	const phoneRegExp = /^0[0-9]{9}$/g;

	const loginValidationSchema = Yup.object({
		name: Yup.string().required("Vui lòng nhập họ và tên!"),
		address: Yup.string().required("Vui lòng nhập địa chỉ!"),
		birthYear: Yup.string()
			.min(4, "Năm sinh gồm 4 chữ số")
			.max(4, "Năm sinh gồm 4 chữ số")
			.required("Vui lòng nhập năm sinh!"),
		phone: Yup.string()
			.required("Vui lòng nhập số điện thoại!")
			.matches(phoneRegExp, "Vui lòng nhập số điện thoại hợp lệ!"),
	});

	const onSubmitLogin = async (data: any) => {
		setIsLoading(true);
		try {
			await postCustomerPreSave({
				phone: data.phone,
				name: data.name,
				address: data.address,
				birth_year: data.birthYear,
			});
			toastSingleMode({
				type: "success",
				description: "Lưu thông tin thành công!",
			});
			formMethodsRef.current.reset();
		} catch (error) {
			const errors = error as AxiosError;
			if (errors.response?.status === 422 || errors.response?.status === 500) {
				toastSingleMode({
					type: "error",
					description: "Năm sinh không hợp lệ!",
				});
			} else if (errors.response?.status === 409) {
				toastSingleMode({
					type: "error",
					description: "Số điện thoại đã được đăng ký!",
				});
			} else {
				toastSingleMode({
					type: "error",
					description: "Đã xảy ra lỗi!",
				});
			}
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<General helmet={<title>Thông tin khách hàng</title>}>
			<Container>
				<div className="d-flex justify-content-center mb-5 pb-2 pt-4">
					<div className="mx-auto" style={{ width: 200 }}>
						<Image src={banner} alt="banner" />
					</div>
				</div>
				<div className="t-preCustomerInfo">
					<div className="u-pb-5">
						<Heading>THÔNG TIN KHÁCH HÀNG</Heading>
					</div>

					<FormContainer
						validationSchema={loginValidationSchema}
						onSubmit={onSubmitLogin}
						register={register}
					>
						<FormField label="Số điện thoại">
							<PhonefieldHookForm name="phone" />
						</FormField>
						<FormField label="Họ tên khách hàng">
							<TextfieldHookForm name="name" />
						</FormField>

						<FormField label="Địa chỉ">
							<TextfieldHookForm name="address" />
						</FormField>

						<FormField label="Năm sinh">
							<PhonefieldHookForm name="birthYear" />
						</FormField>

						<div className="d-flex justify-content-center u-mt-20 u-pt-15">
							<div style={{ marginRight: 10 }}>
								<Button onClick={handleBack}>Quay lại</Button>
							</div>
							<Button loading={isLoading} type="submit">
								Lưu thông tin
							</Button>
						</div>
					</FormContainer>
				</div>
			</Container>
		</General>
	);
};

export default PreCustomerInfo;
