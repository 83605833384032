/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { ChangeEvent, useEffect, useRef, useState } from "react";

import { AxiosError } from "axios";
import * as Yup from "yup";

import banner from "assets/images/common/ensureGold.png";
import { Button } from "components/atoms/Button";
import { Checkbox } from "components/atoms/Checkbox";
import { Heading } from "components/atoms/Heading";
import { Image } from "components/atoms/Image";
import { PhonefieldHookForm } from "components/atoms/PhoneField";
import { Radio } from "components/atoms/Radio";
import { Text } from "components/atoms/Text";
import { TextfieldHookForm } from "components/atoms/TextField";
import { toastSingleMode } from "components/atoms/Toastify";
import { FormField } from "components/molecules/FormField";
import { Container } from "components/organisms/Grid";
import { Modal } from "components/organisms/Modal";
import { General } from "components/pages/general";
import { Link } from "components/utils/Link";
import { confirm } from "contexts/Dialog";
import { FormContainer, useFormContainer } from "helpers/form";
import { useAsyncAction } from "hooks/useAsyncAction";
import { SavedCustomer } from "pages";
import {
	apiFindCustomerByPhone,
	apiSaveCustomer,
	apiSendOtp,
	apiVerifyOtp,
	getPreActiveCustomer,
} from "services/Customer";
import { CustomerByPhone, SavingCustomer } from "services/Customer/types";
import { useAppSelector } from "store";

const objectRadio = [
	{
		value: 1,
		label: "Người chăm sóc",
	},
	{
		value: 2,
		label: "Người sử dụng",
	},
];

interface Props {
	onSubmit?: () => void;
	onReload?: () => void;
	getRemainingGift: (quantity: number) => void;
	savedCustomer?: SavedCustomer;
	getSavedCustomer: (savedCustomer: SavedCustomer) => void;
	handleBack?: () => void;
	channel?: "MT" | "GT";
}

const phoneRegExp = /^0[0-9]{9}$/g;

const IndexPage: React.FC<Props> = ({
	onSubmit,
	getRemainingGift,
	onReload,
	savedCustomer,
	getSavedCustomer,
	handleBack,
	channel,
}) => {
	const { register, methodsRef: formMethodsRef } = useFormContainer();

	const verifiedOtpRef = useRef(false);
	const timerRef = useRef<HTMLSpanElement | null>(null);

	const [giftState, setGiftState] = useState(false);
	const [sachetState, setSachetState] = useState(false);
	const [sampleState, setSampleState] = useState(!!savedCustomer?.sample);
	const [policyCheck, setPolicyCheck] = useState(false);
	const [policyPopup, setPolicyPopup] = useState(false);
	const [canVerifyOtp, setCanVerifyOtp] = useState(false);
	const [otpDeactive, setOtpDeactive] = useState(false);
	const [countDown, setCountDown] = useState(false);
	const [formDataActive, setFormDataActive] = useState(-1); // 0: Ensure; 1: Glucerna
	const { tempData: tempFormData } = useAppSelector((state) => state.stores);
	const [ensureState, setEnsureState] = useState(savedCustomer?.ensureObj);

	const activeButtonRef = useRef(false);
	/**
	 * Ignore Form: When user doesn't choose 'sachet Gift' checkbox
	 * using form data from previous form (api customer/phone glucerna or ensure) & submit save customer
	 */
	const [ignoreForm, setIgnoreForm] = useState<SavingCustomer>();

	const [sendOtpExec, sendOtpState] = useAsyncAction(apiSendOtp, {
		onSuccess: () => {
			setCountDown(true);
		},
		onFailed: () => {
			toastSingleMode({ message: "Lỗi gửi OTP!", type: "error" });
		},
	});

	const [verifyOtpExec, verifyOtpState] = useAsyncAction(apiVerifyOtp, {
		onSuccess: () => {
			toastSingleMode({ message: "Xác nhận OTP thành công!", type: "success" });
			verifiedOtpRef.current = true;
		},
		onFailed: () => {
			toastSingleMode({ message: "Lỗi xác nhận OTP!", type: "error" });
			verifiedOtpRef.current = false;
		},
	});

	const [
		getPreActiveExec,
		getPreActiveState,
		resetPreActiveState,
	] = useAsyncAction(getPreActiveCustomer, {
		// onFailed: () => {
		// 	formMethodsRef.current.reset();
		// },
	});

	const [findCustomerExec, findCustomerState] = useAsyncAction(
		apiFindCustomerByPhone,
		{
			onSuccess: ({
				data: {
					data: { sachet },
				},
			}) => {
				resetPreActiveState();
				toastSingleMode({
					message: "Số điện thoại đã được xác nhận OTP!",
					type: "success",
				});
				verifiedOtpRef.current = true;
				if (sachet) setSachetState(sachet);
			},
			onFailed: (error: AxiosError) => {
				if (error?.response?.status === 404) {
					getPreActiveExec(formMethodsRef.current.getValues("phone"));
					sendOtpExec({
						data: {
							phone: formMethodsRef.current.getValues("phone"),
						},
						is_glucerna: 0,
					});
					verifiedOtpRef.current = false;
				}
			},
		}
	);

	const [saveCustomerExec, saveCustomerState] = useAsyncAction(
		(params: SavingCustomer, canGoNext: boolean, isGlucerna?: number) =>
			apiSaveCustomer(params, isGlucerna || 0)
				.then(({ data: { data } }) => {
					if (canGoNext && giftState) {
						getRemainingGift(Number(data.todayPrizesLeft));
						getSavedCustomer({
							...data,
							isExchangedGift: giftState,
							ensureObj: ensureState,
						});
						onSubmit?.();
						return;
					}

					confirm({
						title: "Đã lưu thông tin khách hàng",
						okLabel: "OK",
					}).then(() => {
						if (onReload) onReload();
					});
					formMethodsRef.current.reset();
				})
				.catch((error) => {
					toastSingleMode({
						message: "Số điện thoại chưa được xác thực",
						type: "error",
					});
					// console.log("loi: ", error.response.data);
				})
	);

	const customerByPhone = findCustomerState.data?.data?.data || savedCustomer;

	const validationSchema = Yup.object({
		phone: Yup.string()
			.required("Vui lòng nhập số điện thoại!")
			.matches(phoneRegExp, "Vui lòng nhập số điện thoại hợp lệ!"),
		otp:
			verifiedOtpRef.current || otpDeactive
				? Yup.string().notRequired()
				: Yup.string()
						.length(6, "OTP phải bao gồm 6 con số!")
						.required("Vui lòng nhập OTP!"),
		address: Yup.string().required("Vui lòng nhập địa chỉ!"),
		name: Yup.string().required("Vui lòng nhập họ tên!"),
		birthYear: Yup.string()
			.length(4, "Năm sinh gồm 4 con số!")
			.required("Vui lòng nhập năm sinh!"),
	});

	/**
	 * Autofill with previous data in Glucerna form
	 */
	useEffect(() => {
		if (customerByPhone) {
			formMethodsRef.current.setValue("name", customerByPhone.name);
			formMethodsRef.current.setValue("phone", customerByPhone.phone);
			formMethodsRef.current.setValue("address", customerByPhone.address);
			formMethodsRef.current.setValue("birthYear", customerByPhone.birthYear);
		} else {
			formMethodsRef.current.setValue("name", "");
			formMethodsRef.current.setValue("address", "");
			formMethodsRef.current.setValue("birthYear", "");
		}
	}, [customerByPhone, formMethodsRef]);

	useEffect(() => {
		if (getPreActiveState.data) {
			formMethodsRef.current.setValue("name", getPreActiveState.data.name);
			formMethodsRef.current.setValue("phone", getPreActiveState.data.phone);
			formMethodsRef.current.setValue(
				"address",
				getPreActiveState.data.address
			);
			formMethodsRef.current.setValue(
				"birthYear",
				getPreActiveState.data.birthYear
			);
		} else {
			formMethodsRef.current.setValue("name", "");
			formMethodsRef.current.setValue("address", "");
			formMethodsRef.current.setValue("birthYear", "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getPreActiveState.data]);

	const handleCheckPhone = () => {
		activeButtonRef.current = true;
		const { getValues, setError } = formMethodsRef.current;
		const phone = getValues("phone");

		validationSchema.fields?.phone
			.validate(phone)
			.then(() => {
				findCustomerExec({
					phone,
					is_glucerna: 0,
				});
				// sendOtpExec({
				// 	data: {
				// 		phone: formMethodsRef.current.getValues("phone"),
				// 	},
				// 	is_glucerna: 0,
				// });
				// verifiedOtpRef.current = false;
			})
			.catch((error) => {
				setError("phone", error);
			});
	};

	const handleChangeOtp = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		if (value && !canVerifyOtp) {
			setCanVerifyOtp(true);
			setCountDown(false);

			return;
		}

		if (!value && canVerifyOtp) {
			setCanVerifyOtp(false);
			setCountDown(false);
		}
	};

	const handleVerifyOtp = () => {
		const { getValues, setError } = formMethodsRef.current;
		const { otp, phone } = getValues();

		validationSchema.fields?.otp
			.validate(otp)
			.then(() => {
				verifyOtpExec({
					params: {
						otp,
						phone,
					},
					is_glucerna: 0,
				});
			})
			.catch((error) => {
				setError("otp", error);
			});
	};

	const handleChangeGift = (event: ChangeEvent<HTMLInputElement>) => {
		setGiftState(!!Number(event?.target.value));
	};

	const handleChangeSample = (event: ChangeEvent<HTMLInputElement>) => {
		setSampleState(!!Number(event?.target.value));
	};

	const handleChangeSachet = (event: ChangeEvent<HTMLInputElement>) => {
		setSachetState(!!Number(event?.target.value));
	};

	const handleSaveCustomer = (canGoNext: boolean) => (
		formData: SavingCustomer
	) => {
		if (!sachetState && ignoreForm) {
			saveCustomerExec(
				{
					...formData,
					sachet: sachetState,
					ensureTarget: Number(ensureState),
					name: ignoreForm.name,
					phone: ignoreForm.phone,
					address: ignoreForm.address,
					birthYear: ignoreForm.birthYear,
				},
				canGoNext
			);
		} else {
			saveCustomerExec(
				{ ...formData, sachet: sachetState, ensureTarget: Number(ensureState) },
				canGoNext
			);
		}
	};

	const handleContinue = () => {
		if (!verifiedOtpRef.current && !otpDeactive) {
			toastSingleMode({
				type: "error",
				description: "Vui lòng xác nhận OTP!",
			});
			return;
		}
		if (!policyCheck) {
			toastSingleMode({
				type: "error",
				description: "Vui lòng chọn đồng ý điều khoản",
			});
			return;
		}

		if (!ensureState) {
			toastSingleMode({
				type: "error",
				description: "Vui lòng chọn đối tượng",
			});
			return;
		}

		const { handleSubmit } = formMethodsRef.current;

		if (giftState) {
			// NOTE: Save to make later api workable
			handleSubmit(handleSaveCustomer(true))();
			return;
		}

		handleSubmit(handleSaveCustomer(true))();
		// if (sachetState) {
		// 	handleSubmit(handleSaveCustomer(false))();
		// }
		// // if (sampleState) {
		// // 	handleSubmit(handleSaveCustomer(false))();
		// // }

		// if (ensureState) {
		// 	handleSubmit(handleSaveCustomer(false))();
		// }
	};

	/**
	 * Deactive OTP validation (MT channel only)
	 */
	useEffect(() => {
		if (channel !== "MT") return;
		setOtpDeactive(!sachetState);
	}, [sachetState, channel]);

	/**
	 * check phone number is verified ?
	 */
	useEffect(() => {
		(async () => {
			const phone = formMethodsRef.current.getValues("phone");
			if (phone) {
				try {
					// ensure verified !
					const resEnsure = await apiFindCustomerByPhone({
						phone,
						is_glucerna: 0,
					});
					// console.log("ensure: ", resEnsure.data.data);
					setFormDataActive(0);
					setIgnoreForm(resEnsure.data.data);
				} catch (error) {
					// console.log("glu: ", resGlucerna.data.data);
					const resGlucerna = await apiFindCustomerByPhone({
						phone,
						is_glucerna: 0,
					});
					// setFormDataActive(1);
					setIgnoreForm(resGlucerna.data.data);
				}
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!activeButtonRef.current) return undefined;
		if (!countDown) return undefined;

		const timerTarget = timerRef.current;

		if (!timerTarget) return undefined;

		let timeLeft = 30;

		const countDownTimer = setInterval(() => {
			if (timeLeft <= 0) {
				clearInterval(countDownTimer);
				setCountDown(false);
			} else {
				timerTarget.innerHTML = `${
					timeLeft < 10 ? `0${timeLeft}` : timeLeft
				} giây`;
			}

			timeLeft -= 1;
		}, 1000);

		return () => {
			clearInterval(countDownTimer);
		};
	}, [countDown, findCustomerState.loading, sendOtpState.loading]);

	return (
		<General helmet={<title>Thông tin khách hàng</title>}>
			<Container>
				<div className="d-flex justify-content-center mb-5 pb-2 pt-4">
					<Image src={banner} alt="banner" />
				</div>

				<FormContainer
					validationSchema={validationSchema}
					onSubmit={handleSaveCustomer}
					register={register}
				>
					<section>
						<div className="u-pb-5">
							<Heading>THÔNG TIN KHÁCH HÀNG</Heading>
						</div>

						<FormField label="Số điện thoại" modifiers="customerInfo">
							<div className="d-flex">
								<div className="flex-grow-1">
									<PhonefieldHookForm
										name="phone"
										defaultValue={customerByPhone?.phone}
									/>
								</div>
								{!canVerifyOtp && (
									<div className="u-ml-10 u-mt-sm-0 d-flex minw-55">
										<Button
											modifiers={["otp", "smallSize"]}
											disabled={
												activeButtonRef.current &&
												(findCustomerState.loading ||
													sendOtpState.loading ||
													verifyOtpState.loading ||
													countDown)
											}
											loading={
												activeButtonRef.current &&
												(findCustomerState.loading ||
													sendOtpState.loading ||
													verifyOtpState.loading)
											}
											onClick={handleCheckPhone}
										>
											{activeButtonRef.current &&
											(findCustomerState.data || sendOtpState.data) ? (
												<span className="d-block">
													Gửi lại OTP
													<br />
													{countDown && <span ref={timerRef} />}
												</span>
											) : (
												<span className="d-block">Lấy mã OTP</span>
											)}
										</Button>
									</div>
								)}
							</div>
						</FormField>

						<FormField label="Họ tên khách hàng" modifiers="customerInfo">
							<TextfieldHookForm
								name="name"
								defaultValue={customerByPhone?.name}
							/>
						</FormField>

						{!verifiedOtpRef.current && (
							<FormField label="Mã OTP" modifiers="customerInfo">
								<div className="d-flex">
									<div className="flex-grow-1">
										<PhonefieldHookForm name="otp" onChange={handleChangeOtp} />
									</div>
									{canVerifyOtp && (
										<div className="u-ml-10 u-mt-sm-0 d-flex minw-55">
											<Button
												modifiers={["otp", "smallSize"]}
												disabled={
													findCustomerState.loading ||
													sendOtpState.loading ||
													verifyOtpState.loading ||
													countDown
												}
												loading={
													findCustomerState.loading ||
													sendOtpState.loading ||
													verifyOtpState.loading
												}
												onClick={handleVerifyOtp}
											>
												<span>Xác nhận OTP</span>
											</Button>
										</div>
									)}
								</div>
							</FormField>
						)}

						<FormField label="Địa chỉ" modifiers="customerInfo">
							<TextfieldHookForm
								name="address"
								defaultValue={customerByPhone?.address}
							/>
						</FormField>

						<FormField label="Năm sinh" modifiers="customerInfo">
							<TextfieldHookForm
								name="birthYear"
								defaultValue={customerByPhone?.birthYear}
							/>
						</FormField>
					</section>

					<section className="u-pt-30">
						<Checkbox
							value="policy"
							name="policy"
							checked={policyCheck}
							onChange={() => {
								setPolicyCheck(!policyCheck);
							}}
						>
							Tôi đã đọc và đồng ý với các{" "}
							<p
								onClick={() => setPolicyPopup(true)}
								style={{
									textDecoration: "underline",
									display: "inline",
									cursor: "pointer",
								}}
							>
								điều khoản và điều kiện
							</p>
						</Checkbox>
					</section>

					<section className="u-pt-15 u-pb-30">
						<Heading type="h4" noBackground>
							Đối với nhãn hàng Ensure, bạn thuộc đối tượng nào dưới đây?
						</Heading>
						<FormField modifiers={["customerInfo", "block", "noMargin"]}>
							<div
								className="d-flex align-items-center justify-content-between u-mt-10"
								style={{ maxWidth: 375 }}
							>
								{objectRadio.map((val, idx) => (
									<Radio
										value={val.value}
										// name="glucernaObj"
										key={idx.toString()}
										checked={val.value === ensureState}
										onChange={() => setEnsureState(val.value)}
									>
										{val.label}
									</Radio>
								))}
							</div>
						</FormField>
					</section>

					<section className="u-mt-20 u-pt-20">
						<Heading>HOẠT ĐỘNG THAM GIA</Heading>

						{customerByPhone?.sachet ? (
							<div className="u-mt-15">
								<Text>Khách hàng đã nhận sachet </Text>
							</div>
						) : (
							<FormField
								label="Tặng sachet Ensure Gold GH"
								modifiers="customerInfo"
							>
								<div
									className="d-flex align-items-center justify-content-between u-mt-10"
									style={{ maxWidth: 180 }}
								>
									<Radio
										value="1"
										name="sachet"
										checked={sachetState}
										onChange={handleChangeSachet}
									>
										Có
									</Radio>
									<Radio
										value="0"
										name="sachet"
										checked={!sachetState}
										onChange={handleChangeSachet}
									>
										Không
									</Radio>
								</div>
							</FormField>
						)}
						{/* <FormField
							label="Tặng sample Glucerna liquid"
							modifiers="customerInfo"
						>
							<div
								className="d-flex align-items-center justify-content-between u-mt-10"
								style={{ maxWidth: 180 }}
							>
								<Radio
									value="1"
									name="sample"
									checked={sampleState}
									onChange={handleChangeSample}
								>
									Có
								</Radio>
								<Radio
									value="0"
									name="sample"
									checked={!sampleState}
									onChange={handleChangeSample}
								>
									Không
								</Radio>
							</div>
						</FormField> */}

						<FormField label="Đổi quà" modifiers="customerInfo">
							<div
								className="d-flex align-items-center justify-content-between"
								style={{ maxWidth: 180 }}
							>
								<Radio
									value="1"
									name="gift"
									onChange={handleChangeGift}
									checked={giftState}
								>
									Có
								</Radio>
								<Radio
									value="0"
									name="gift"
									onChange={handleChangeGift}
									checked={!giftState}
								>
									Không
								</Radio>
							</div>
						</FormField>
					</section>

					<div style={{ textAlign: "center", marginTop: 30 }}>
						<div style={{ marginRight: 10, display: "inline-block" }}>
							<Button onClick={handleBack}>Quay lại</Button>
						</div>
						<Button
							onClick={handleContinue}
							loading={saveCustomerState.loading}
							disabled={saveCustomerState.loading}
						>
							{!giftState ? "Hoàn tất" : "Tiếp tục"}
						</Button>
					</div>
				</FormContainer>
			</Container>
			<Modal
				heading="Điều khoản và điều kiện"
				isOpen={policyPopup}
				closable
				shouldCloseOnEsc
				shouldCloseOnOverlayClick
				onCloseModal={() => setPolicyPopup(false)}
			>
				<Text modifiers={["yankeesBlue", "18x26"]}>
					1. Tôi đồng ý rằng thông tin mà tôi cung cấp sẽ được Abbott / 3A thu
					thập và sử dụng để cung cấp cho tôi thông tin về chăm sóc sức khoẻ,
					dinh dưỡng, quảng cáo, khuyến mãi sản phẩm. Tôi đồng ý rằng Abbott /
					3A có thể chia sẻ thông tin cá nhân này với các chi nhánh và các bên
					thứ ba được uỷ quyền để hỗ trợ thu thập, xử lý và sử dụng các thông
					tin mà tôi cung cấp.
				</Text>
				<br />
				<Text modifiers={["yankeesBlue", "18x26"]}>
					2. Tôi đồng ý rằng Abbott / 3A được gửi nhiều hơn số lượng tin nhắn,
					thư điện tử, cuộc gọi theo quy định pháp luật, và được liên lạc quảng
					cáo trước 7 giờ và sau 22 giờ ( đối với tin nhắn) và trước 8 giờ và
					sau 17 giờ ( đối với gọi điện ).
				</Text>
			</Modal>
		</General>
	);
};

export default IndexPage;
