import React, { useRef, useState } from "react";

import banner from "assets/images/common/banner.png";
import { Button } from "components/atoms/Button";
import { Image } from "components/atoms/Image";
import { toastSingleMode } from "components/atoms/Toastify";
import { Container } from "components/organisms/Grid";
import { General } from "components/pages/general";
import { useAsyncAction } from "hooks/useAsyncAction";
import { apiPostPhoto } from "services/Photo";

import Processing, { SelfieProcessProps } from "./process";

interface Props {
	onSubmit?: () => void;
}

const Photo: React.FC<Props> = ({ onSubmit }) => {
	// const imgFileOneRef = useRef<File | null>(null);
	// const imgFileTwoRef = useRef<File | null>(null);
	const camRef = useRef<SelfieProcessProps>(null);
	const imgFirstSrcRef = useRef<string>("");
	const [permission, setPermission] = useState<PermissionState>("prompt");
	const [activeCam, setActiveCam] = useState(true);
	const [currentStep, setStep] = useState<number>(1);
	const [imgFile, setImgFile] = useState<File>();

	const [postPhotoExec, postPhotoState] = useAsyncAction(apiPostPhoto, {
		onSuccess: () => {
			toastSingleMode({ message: "Upload ảnh thành công!", type: "success" });
			onSubmit?.();
		},
		onFailed: () => {
			toastSingleMode({ message: "Đã xảy ra lỗi upload ảnh!", type: "error" });
		},
	});

	const handleTakePhoto = async (step: number) => {
		setStep(step);
		if (camRef.current) {
			const res = await camRef.current.takeSelfie();

			imgFirstSrcRef.current = res;
			fetch(res)
				.then((r) => r.blob())
				.then((a) => {
					if (a) {
						const file = new File([a], `doiquaensure2022.jpeg`, {
							type: "image/jpeg",
						});
						setImgFile(file);
					}
				});
			// imgFileTwoRef.current = dataURLtoFile(res, "customer");
		}
		setActiveCam(false);
	};

	const reTakePicture = () => {
		// imgFileOneRef.current = null;
		// imgFileTwoRef.current = null;
		setActiveCam(true);
		setStep(1);
	};

	const handleTakePhotoStepTwo = () => {
		// if (imgFileOneRef.current && imgFileTwoRef.current) {
		// 	postPhotoExec({
		// 		billImage: imgFileOneRef.current,
		// 		customerImage: imgFileTwoRef.current,
		// 	});
		// } else {
		// 	setActiveCam(true);
		// 	setStep(2);
		// }
		if (imgFile) {
			postPhotoExec({
				customerImage: imgFile,
			});
		}
	};

	return (
		<General helmet={<title>Chụp hình</title>}>
			<div className="t-photo_thumbnail">
				<div style={{ maxWidth: 540 }} className="mx-auto t-photo_image">
					<Image
						src={banner}
						alt="banner"
						aspectRatio="loginbanner"
						modifiers="transparent"
					/>
				</div>
			</div>
			<div className="t-photo">
				{!activeCam && (
					<div style={{ maxWidth: 540 }} className="mx-auto t-photo_image">
						<Image
							src={imgFirstSrcRef.current}
							alt="banner"
							aspectRatio="capture"
							modifiers="transparent"
						/>
					</div>
				)}
				<Container>
					{activeCam && (
						<div className="t-photo_capture">
							<Processing
								ref={camRef}
								handlePermission={(val) => {
									setPermission(val);
								}}
							/>
						</div>
					)}
					<div
						className="d-flex justify-content-center u-mt-10 u-pt-15 flex-column mx-auto"
						style={{ maxWidth: 400 }}
					>
						{/* {currentStep === 1 && activeCam ? (
							<Button
								disabled={permission !== "granted"}
								fullWidth
								onClick={() => handleTakePhoto(1)}
							>
								Chụp hình hóa đơn
							</Button>
						) : (
							<Button
								disabled={permission !== "granted"}
								fullWidth
								onClick={reTakePicture}
							>
								Chụp lại
							</Button>
						)} */}

						<div className="u-mt-20">
							{activeCam && currentStep === 1 ? (
								<Button
									fullWidth
									onClick={() => handleTakePhoto(1)}
									disabled={permission !== "granted"}
								>
									<span style={{ fontSize: 12 }}>
										Nhấn để chụp hình khách hàng và quà tặng
									</span>
								</Button>
							) : (
								<>
									<Button
										disabled={permission !== "granted"}
										fullWidth
										onClick={reTakePicture}
									>
										Chụp lại
									</Button>
									<br />
									<br />
									<Button
										disabled={
											// !imgFileOneRef.current ||
											postPhotoState.loading || permission !== "granted"
										}
										fullWidth
										loading={postPhotoState.loading}
										onClick={handleTakePhotoStepTwo}
									>
										<span>Hoàn tất</span>
									</Button>
									<br />
								</>
							)}
						</div>
					</div>
				</Container>
			</div>
		</General>
	);
};

export default Photo;
