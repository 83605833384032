/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { toastSingleMode } from "components/atoms/Toastify";
import { alert } from "contexts/Dialog";
import store from "store";
import authSlice from "store/Auth/reducer";
import storesSlice from "store/Stores/reducer";

import { clearLocalStorage, getCustomerSessionInfo } from "./Sessions";

declare module "axios" {
	export interface AxiosInstance {
		request<T = any, R = AxiosResponse<T>>(
			config: AxiosRequestConfigCustom
		): Promise<R>;
		get<T = any, R = AxiosResponse<T>>(
			url: string,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		delete<T = any, R = AxiosResponse<T>>(
			url: string,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		head<T = any, R = AxiosResponse<T>>(
			url: string,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		options<T = any, R = AxiosResponse<T>>(
			url: string,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		post<T = any, R = AxiosResponse<T>>(
			url: string,
			data?: any,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		put<T = any, R = AxiosResponse<T>>(
			url: string,
			data?: any,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		patch<T = any, R = AxiosResponse<T>>(
			url: string,
			data?: any,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
	}
}

const http = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
});

type AxiosRequestConfigCustom = AxiosRequestConfig & {
	requireAuthentication?: boolean;
};

let isTokenExpired: boolean = false;

http.interceptors.request.use(
	(config: AxiosRequestConfigCustom) => {
		if (config.requireAuthentication) {
			const sessionInfo = getCustomerSessionInfo();
			if (sessionInfo) {
				Object.assign(config.headers, {
					Authorization: `Bearer ${sessionInfo.accessToken}`,
				});
			}
		}
		return config;
	},
	(error: AxiosError<any>) => {
		return Promise.reject(error);
	}
);

http.interceptors.response.use(
	(response: AxiosResponse<any>) => {
		return response;
	},
	async (error: AxiosError<any> & { config: AxiosRequestConfigCustom }) => {
		if (error.response?.status === 401) {
			if (store.getState().auth.status === "UNAUTH") {
				store.dispatch(authSlice.actions.logout());
				store.dispatch(storesSlice.actions.clearStoreInfors());
				store.dispatch(storesSlice.actions.updateProductType("default"));
				toastSingleMode({
					type: "info",
					message: "Phiên đăng nhập",
					description:
						"Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập vào để tiếp tục.",
				});
				clearLocalStorage();
			}

			if (store.getState().auth.status === "AUTH" && !isTokenExpired) {
				store.dispatch(storesSlice.actions.updateProductType("default"));
				isTokenExpired = true;
				alert({
					title: "Phiên đăng nhập",
					content:
						"Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập vào để tiếp tục.",
					okLabel: "Đóng",
				}).then(() => {
					store.dispatch(authSlice.actions.logout());
					store.dispatch(storesSlice.actions.clearStoreInfors());
					isTokenExpired = false;
				});
				clearLocalStorage();
			}
		}

		return Promise.reject(error);
	}
);

export default http;
